import React from "react"
import Title from "../Text/Title"
import { useTranslation } from "react-i18next"
import "./HowToListen.scss"
import { getExternalUrl, getSingleImage, getTitle } from "../../helpers/Publications"
import { imageUrl } from "../../helpers/ImageUrl"

export default function HowToListen({platforms, title}) {
  const { t } = useTranslation()
  return (
    <div className="howto-listen">
      <Title light centered>
        {title ? getTitle(title) : t("howToListen.title")}
      </Title>
      <div className="howto-listen--options">
        {platforms.map((platform, index) => (
          <a key={index} href={getExternalUrl(platform.url)} target="_blank" rel="noreferrer">
            <img src={imageUrl(
              getSingleImage(
                platform?.image
              )
            )} alt={getTitle(platform.title)} />
          </a>
        ))}
      </div>
    </div>
  )
}
