import React from "react"
import { Router } from "@reach/router"
import HowToListenOverview from "../components/HowToListen/Overview"

const HowToListen = () => {
  return (
    <Router>
      <HowToListenOverview path="hoe-luisteren" />
    </Router>
  )
}

export default HowToListen
