import React, { useEffect, useMemo, useState } from "react"
import Title from "../../components/Text/Title"
import HowToListen from "../../components/HowToListen/HowToListen"
import "./Overview.scss"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import { imageUrl } from "../../helpers/ImageUrl"
import { getFrequency, getSingleImage, getTitle } from "../../helpers/Publications"

function HowToListenOverview() {
  const { getHowToListenPublication } = useGuestApi();
  const [content, setContent] = useState(null);
  useEffect(() => {
    getHowToListenPublication().then(response => {
      setContent(response);
    })
  }, [getHowToListenPublication])

  const platforms = useMemo(() => {
    return content?.platforms?.items.map(publication => {
      const keyName = Object.keys(publication.items)[0]
      publication.items[keyName].id = publication.id
      publication.items[keyName].slug = publication.slug
        ? publication.slug[keyName]
        : ""
      publication.items[keyName].publish_on = publication.publish_on
        ? publication.publish_on[keyName]
        : ""
      return publication.items[keyName]
    })
  }, [content])

  const frequencies = useMemo(() => {
    return content?.provinces?.items.map(publication => {
      const keyName = Object.keys(publication.items)[0]
      publication.items[keyName].id = publication.id
      publication.items[keyName].slug = publication.slug
        ? publication.slug[keyName]
        : ""
      publication.items[keyName].publish_on = publication.publish_on
        ? publication.publish_on[keyName]
        : ""

      if(publication?.items[keyName]?.cities) {
        publication.items[keyName].cities = publication?.items[keyName]?.cities?.items?.map(city => {
          const keyName = Object.keys(city.items)[0]
          city.items[keyName].id = city.id
          city.items[keyName].publish_on = city.publish_on
            ? city.publish_on[keyName]
            : ""
          return city.items[keyName]
        })
      }
      return publication.items[keyName]
    })
  }, [content])

  if(!content) {
    return null;
  }

  return (
      <div className="freq">
        <div className="freq__inner container">
          <div className="freq__visual">
            <img src={imageUrl(
              getSingleImage(
                content?.image
              ),
              1200,
              560
            )} alt="" />
          </div>
          <div className="freq__content">
            <HowToListen title={content?.title} platforms={platforms} />
            <Title centered light secondary>
              Frequenties
            </Title>
            {frequencies.map((item, index) => (
              <div className="freq__province" key={index}>
                <div className="freq__province--col">
                  <h2 className="freq__province--title">{getTitle(item?.title)}</h2>
                </div>
                <div className="freq__province--col-2">
                  {item?.cities?.length > 0 && (
                  <ul>
                    {item.cities.map((city, i) => (
                      <li key={i}>
                        {getTitle(city?.title)} <span>{getFrequency(city?.frequency)}</span>
                      </li>
                    ))}
                  </ul>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

  )
}

export default withGuestLayout(HowToListenOverview, {
  page: "Hoe luisteren",
})
